export const initializeFabricCanvas = () => {
  const canvas = new global.fabric.Canvas('main');
  canvas.calcOffset();
  canvas.isDrawingMode = false;
  canvas.preserveObjectStacking = true;
  canvas.selection = false;
  canvas.selectionBorderColor = "#5f5f5f";
  canvas.selectionColor = "rgba(255, 255, 255, 0.3)";
  canvas.selectionDashArray = [3, 8];
  canvas.selectionLineWidth = 1;
  return canvas;
}
