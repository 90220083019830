export const loadImageFromURL = (imageSrc) => {
  return new Promise((resolve, reject) => {
    loadImage(imageSrc, resolve, reject);
  });
};

function loadImage(imageSrc, resolve, reject, reqCount = 0) {
  reqCount++;
  const image = new Image();
  image.setAttribute('crossorigin', 'anonymous');
  image.onload = function () {
    const fabricImage = new global.fabric.Image(image);
    fabricImage.crossOrigin = 'anonymous';
    scaleFabricImage(fabricImage);
    resolve(fabricImage);
  };
  image.onerror = function () {
    if (reqCount === 30) {
      reject(new Error('Image failed to create!'));
    } else {
      setTimeout(() => {
        loadImage(imageSrc, resolve, reject, reqCount);
      }, 2000);
    }
  };
  image.src = imageSrc + '?_=' + Date.now();
}

function scaleFabricImage(fabricImage, retry = 1) {
  const container = document.querySelector('div.editing-img');
  if ((fabricImage.getScaledWidth() > container.clientWidth) || (fabricImage.getScaledHeight() > container.clientHeight)) {
    let widthScaleFactor = Math.floor(fabricImage.getScaledWidth() / container.clientWidth);
    let heightScaleFactor = Math.floor(fabricImage.getScaledHeight() / container.clientHeight);
    let averageScaleFactor = Math.max(widthScaleFactor, heightScaleFactor);
    (averageScaleFactor > 1) ? fabricImage.scale((1 / averageScaleFactor)) : fabricImage.scale(1 - retry * 0.05);
    // console.log(`After Image Scaling => width:${fabricImage.getScaledWidth()}, height:${fabricImage.getScaledHeight()} retry:${retry}`);
    return scaleFabricImage(fabricImage, ++retry);
  } else {
    return fabricImage;
  }
}
