import React, { useState, useEffect } from 'react';
import RectangleShape from './toolbarOptions/rectangle';
import CircleShape from './toolbarOptions/circle';
import TextOption from './toolbarOptions/text';
import ColorPickerOption from './toolbarOptions/colorPicker';
import ArrowShape from './toolbarOptions/arrow';
import LineShape from './toolbarOptions/line';
import PencilOption from './toolbarOptions/pencil';
import EraserOption from './toolbarOptions/eraser';
import PixelateOption from './toolbarOptions/pixelate';
import { toolsList } from '../util/tools-list';

const Toolbar = ({ editor }) => {
  const [color, setColor] = useState('rgb(0, 0, 0)');
  const [activeTool, setActiveTool] = useState(null);
  const props = {
    editor,
    color,
    activeTool
  };
  useEffect(() => {
    if (editor && setActiveTool) {
      const updateActiveTool = (event) => {
        if (event.target
          && event.target.closest
          && event.target.closest('li')
          && event.target.closest('li').className
          && toolsList.indexOf(event.target.closest('li').className.trim().replace(' active', '')) !== -1) {
          event.stopPropagation();
          if (editor.__eventListeners) {
            editor.off('mouse:up');
            editor.off('mouse:down');
            editor.off('mouse:move');
          }
          let newTool = event.target.closest('li').className.trim().replace(' active', '');
          if (activeTool !== newTool) {
            editor.defaultCursor = 'crosshair';
            editor.selection = newTool === 'text';
            editor.isDrawingMode = (newTool === 'pencil') || (newTool === 'eraser');
            setActiveTool(newTool);
          } else {
            setActiveTool('none');
            editor.defaultCursor = 'default';
            editor.selection = true;
            editor.isDrawingMode = false;
          }
        }
      }
      let actions = document.querySelector('ul.actions');
      actions.addEventListener('click', updateActiveTool);
      return () => {
        if(actions !== null){
          actions.removeEventListener('click', updateActiveTool);
        }
      }
    }
  }, [editor, activeTool, setActiveTool])
  return (
    <aside>
      <ul className="actions">
        <ColorPickerOption color={color} setColor={setColor} />
        <TextOption {...props} />
        <RectangleShape {...props} />
        <ArrowShape {...props} />
        <PencilOption {...props} />
        <CircleShape {...props} />
        <LineShape {...props} />
        <EraserOption {...props} />
        <PixelateOption {...props} />
      </ul>
    </aside>
  );
};

export default Toolbar;
