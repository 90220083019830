import React from 'react'

import { CustomPicker } from 'react-color'
import { Saturation, EditableInput, Hue } from 'react-color/lib/components/common'


export const MyPicker = ({ hex, hsl , hsv , onChange , rgb  }) => {
  const styles = {
    hue: {
      // height: 10,
      // position: 'relative',

    },
    // input: {
    //   border: `1px solid ${ hex }`,
    // },
    swatch: {
      background: hex,
    },
  }
  return (
    <div className="color-picker-wrp">
      <div></div>
      <div>
        <Saturation hsv={hsv} hsl={hsl}
        onChange={ onChange } />
      </div>

      <div>
        <EditableInput
          style={{ input: styles.input }}
          value={ hex }
          onChange={ onChange }
        />
        <div style={ styles.swatch } />
      </div>
      <div className="color-bar">
        <Hue hsl={ hsl } onChange={ onChange } direction={ 'vertical' } />
      </div>
    </div>
  )
}

export default CustomPicker(MyPicker)
