import React, { useEffect } from "react";

const TextOption = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'text') {
      let previousTextBox = null;
      // clear Previous Listeners
      editor.off('mouse:up');
      editor.off('mouse:down');
      // set the listeners with new computed values
      editor.on('mouse:down', (event) => {
        event.e.stopPropagation();
        if (event.target && event.target instanceof global.fabric.Textbox) {
          return;
        }
        if (previousTextBox) {
          previousTextBox = null;
          return;
        }
        editor.discardActiveObject();
        editor.renderAll();
        const coords = {
          start: {},
          stop: {}
        };
        const obj = new global.fabric.Textbox('TYPE', {
          fill: color,
          stroke: 'rgba(255, 255, 255, 1)',
          // strokeWidth: '1',
          fontWeight: 'bold',
          fontFamily: 'Arial',
          shadow: new global.fabric.Shadow({
            blur: 4,
            offsetX: 0,
            offsetY: 2,
            affectStroke: true,
            color: 'rgba(0, 0, 0, 0.32)',
          })
        });
        coords.start = {
          ...event.absolutePointer
        };
        editor.on('mouse:up', (event) => {
          event.e.stopPropagation();
          coords.stop = {
            ...event.absolutePointer
          };
          obj.setOptions(computeOptions(coords));
          editor.add(obj).setActiveObject(obj).renderAll();
          obj.enterEditing();
          obj.selectAll();
          editor.off('mouse:up');
          previousTextBox = obj;
        })
      });
      // update the color of the Active Text Box
      let activeObject = editor.getActiveObject();
      if (activeObject instanceof global.fabric.Textbox) {
        activeObject.set('fill', color);
        editor.renderAll();
      }
    }
  }, [editor, color, activeTool]);
  return (
    <li className={`text ${activeTool === 'text' ? 'active' : ''}`} data-tooltip="Text">
      <i>
        <svg width="16" height="18" viewBox="0 0 16 18">
          <path
            fill="#FFF"
            d="M12.337 18v-.9c-.277-.01-.663-.064-1.159-.166-.496-.101-.822-.181-.977-.24-.302-.127-.514-.307-.635-.54-.12-.232-.18-.488-.18-.767V.989h.75c.345 0 .72.009 1.126.026.406.017.742.042 1.01.076.285.042.58.21.887.501.306.292.589.632.847 1.021.25.372.477.768.68 1.186.203.419.343.78.42 1.085H16V0H0v4.884h.906c.078-.28.216-.632.414-1.06.199-.427.428-.83.686-1.21.26-.381.542-.722.848-1.022.307-.3.602-.467.887-.501.363-.042.742-.07 1.14-.083.396-.012.728-.019.996-.019h.75v14.512c0 .347-.066.624-.2.83-.134.208-.343.367-.628.477-.19.076-.533.14-1.029.19-.496.05-.865.084-1.107.101V18h8.674z"
          />
        </svg>
      </i>
    </li>
  )
}

function computeOptions(coords) {
  let left = coords.start.x;
  let top = coords.start.y;
  let width = coords.stop.x - coords.start.x;
  let fontSize = coords.stop.y - coords.start.y;
  if (Math.sign(width) === -1) {
    left = coords.stop.x;
    width = coords.start.x - coords.stop.x;
  }
  if (Math.sign(fontSize) === -1) {
    top = coords.stop.y;
    fontSize = coords.start.y - coords.stop.y;
  }
  width = (width < 200) ? 200 : width;
  fontSize =  (fontSize > 72) ? 72 : (fontSize < 32) ? 32 : fontSize;
  return {
    top,
    left,
    width,
    fontSize
  };
}

export default TextOption;
