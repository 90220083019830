import React, { useEffect } from "react";

const PencilOption = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'pencil') {
      editor.freeDrawingBrush = new global.fabric.PencilBrush(editor);
      editor.freeDrawingBrush.width = '6';
      editor.freeDrawingBrush.color = color;
    }
  }, [editor, color, activeTool]);
  return (
    <li className={`pencil ${activeTool === 'pencil' ? 'active' : ''}`} data-tooltip="Pen">
      <i>
        <svg width="19" height="20" viewBox="0 0 19 20">
          <path
            fill="#FFF"
            d="M.889 19.375c.033 0 .066-.002.1-.006l4.235-.468 9.027-9.026 1.289-1.29 2.764-2.763c.928-.928.928-2.432 0-3.36l-1.391-1.391c-.464-.464-1.072-.696-1.68-.696-.608 0-1.216.232-1.68.695L.478 14.147l-.471 4.239c-.06.533.36.989.883.989zM15.4 6.205l-2.23-2.232 1.642-1.643c.152-.151.328-.174.42-.174.092 0 .269.023.42.174l1.392 1.392c.231.232.231.609 0 .84L15.4 6.205zM1.9 17.477l.28-2.512 9.732-9.732 2.231 2.231L4.407 17.2l-2.508.277z"
          />
        </svg>
      </i>
    </li>
  )
}

export default PencilOption;
