import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import brand from "@adaptavant/brands/setmore-black";
import { Root } from "@adaptavant/core";
import translations from "@adaptavant/translations/english";
import './styles/App.css';
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Root
          brand={brand} // brand config and tokens
          colorScheme="light" // preferred color scheme
          translations={translations} // preferred language for text that is built into components
        >
          <Route path="/:fullStorageKey">
            <App isDev={false} />
          </Route>
          {process.env.NODE_ENV === "development" ? <App isDev={true} /> : null}
        </Root>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
