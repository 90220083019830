import React from 'react';
import { useEffect } from 'react';
import { ReplyIcon,IconButton } from '@adaptavant/core';

function redoOption(props) {
  let {
    editor,
    objects,
    setIsRedoing,
    setObjects
  } = props;
  if (objects.length > 0) {
    setIsRedoing(true);
    editor.add(objects.pop());
    setObjects(objects);
  }
}

export default function RedoIcon(props) {

  useEffect(() => {
    const redo = (e) => {
      if ((e.keyCode === 90 && e.metaKey && e.shiftKey) || (e.keyCode === 89 && e.ctrlKey)) {
          redoOption(props);
      }
    }
    document.addEventListener('keydown', redo);
    return () => document.removeEventListener('keydown', redo);
  }, [props]);

  return (
    <li>
         <IconButton className="transform scale-x-[-1]"  aria-label="Click me!"
          icon={ReplyIcon}
          size="standard"
          onClick={(e) => { redoOption(props) }}
          variant= "accentPrimary"/>
    </li>
  );
}
