import React, { useEffect } from "react";

const LineShape = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'line') {
      let line, isDown;
      editor.off('mouse:down');
      editor.off('mouse:move');
      editor.off('mouse:up');
      editor.on('mouse:down', function (o) {
        o.e.stopPropagation();
        if (editor.findTarget(o.e)) return;
        isDown = true;
        let pointer = editor.getPointer(o.e);
        let points = [pointer.x, pointer.y, pointer.x, pointer.y];
        line = new global.fabric.Line(points, {
          fill: color,
          stroke: color,
          strokeWidth: 6,
          selectable: true,
          evented: true,
          strokeUniform: true,
          hasControls: true,
          hasBorders: false,
          shadow: new global.fabric.Shadow({
            blur: 2,
            offsetX: 0,
            offsetY: 0,
            affectStroke: true,
            color: 'rgba(0,0,0,0.8)',
          }),
          cornerColor: 'blue',
          cornerSize: 10,
          padding: -3,
          cornerStrokeColor: 'white',
          transparentCorners: false,
          cornerStyle: 'circle',
          hasRotatingPoint: false,
          borderScaleFactor: 2,
          objectCaching: false
        });
        line.setControlsVisibility({ml: false, mt: false, mb: false, mr: false});
        editor.add(line);
      });
      editor.on('mouse:move', function (o) {
        o.e.stopPropagation();
        if (!isDown) return;
        let pointer = editor.getPointer(o.e);
        line.set({
          x2: pointer.x,
          y2: pointer.y
        });
        line.setCoords();
        // editor.setActiveObject(line);
        editor.renderAll();
      });
      editor.on('mouse:up', function (o) {
        o.e.stopPropagation();
        isDown = false;
      });
    }
  }, [editor, color, activeTool]);

  return (
    <li className={`line ${activeTool === 'line' ? 'active' : ''}`} data-tooltip="Line">
      <i>
        <svg width="20" height="20" viewBox="0 0 20 20">
          <path fill="#FFF" fillRule="evenodd" d="M12 23H38V25H12z" transform="rotate(45 34.4 -1.107)" />
        </svg>
      </i>
    </li>
  )
}

export default LineShape;
