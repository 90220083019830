import React, { useState } from 'react';
import Editor from './Editor';
import Toolbar from './Toolbar';
import Loader from './Loader';
import SavingOverlay from './SavingOverlay';

export const Main = (props) => {
  const [showImageLoader, setShowImageLoader] = useState(true);
  return (
    <main>
      {(showImageLoader) ? <Loader /> : null}
      <section className={`image-container ${(!showImageLoader) ? "has-image" : ""}`}>
        {(props.savingImage) ? <SavingOverlay /> : null}
        {
          (props.isMobile) ? (<img className="editing-img" src={props.imageURL} alt='An image' onLoad={() => setShowImageLoader(false)} />) : (
            <Editor
              imageURL={props.imageURL}
              setEditor={props.setEditor}
              setShowImageLoader={setShowImageLoader}
              editor={props.editor}
              fileName={props.fileName}
            />
          )
        }
      </section>
      {(props.editMode) ? <Toolbar editor={props.editor} /> : null}
    </main>
  );
};

export default Main;
