import React from 'react';
import { Loading } from "@adaptavant/core";
const Loader = () => {
  return (
    <section className="main-loader" >
      <Loading size="80" />
      <h4>Loading</h4>
    </section>
  );
};

export default Loader;
