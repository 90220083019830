import React, { useState } from "react";
import { Button,Text } from "@adaptavant/core";

const CopyLinkButton = () => {
  const [copied, setCopied] = useState(false);

  const copyCurrentLink = (event) => {
    event.stopPropagation();
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(global.location.href)
        .then(() => {
          console.log("successfully copied the link!");
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 500);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <li>
      {!copied ? (
        <Button
          variant="accentPrimary"
          className="ml-4 w-28"
          size="large"
          onClick={copyCurrentLink}
        >
          Copy Link
        </Button>
      ) : (
        <Button
          variant="accentPrimary"
          className="ml-4 w-28"
          size="large"
          isDisabled
        >
          <Text className="text-primary text-body-14">Copied</Text>
        </Button>
      )}
    </li>
  );
};

export default CopyLinkButton;
