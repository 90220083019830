import React, { useState, useRef, useEffect } from 'react';
import { Text,TextInput,Field,EditIcon  } from '@adaptavant/core';

const FileName = ({ hasEditAccess, fileName, fullStorageKey, setFileName}) => {
  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const updateFileName = async () => {
    let newFileName = inputRef.current.value;
    console.log('New FileName', newFileName);
    setIsEditing(false);
    if (newFileName.length > 0) {
      setFileName(newFileName);
      let result = await fetch(`${global.location.origin}/update-file-name?fullStorageKey=${fullStorageKey}&fileName=${newFileName}`);
      if (result.ok) {
        result = await result.json();
      }
      console.log('result :', result);
    } else {
      console.warn('trying to set empty string as fileName is blocked');
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      return updateFileName();
    } else if (event.keyCode === 27) {
      return setIsEditing(false);
    }
  }

  useEffect(() => {
    if (inputRef && inputRef.current && isEditing === true) {
      inputRef.current.value = fileName;
      inputRef.current.focus();
    }
  })

  return (
    <>
      <div className="file-name">
        {
          (isEditing) ? (
            <Field className='[&_.border-input]:border-none' label="First name" labelVisibility="hidden">
            <TextInput className="font-stronger ml-2 text-body-12" ref={inputRef}
              type="text"
              placeholder="file name"
              onBlur={updateFileName}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false" />
            </Field>
          ) : <Text className="font-stronger">
              {fileName}
              </Text>
        }
      </div>
      {
        (hasEditAccess && !isEditing) ? (
          <EditIcon className="ml-2 cursor-pointer " size="20"  onClick={() => setIsEditing(true)} />
        ) : null
      }
    </>
  );
}

export default FileName;
