import React from "react";
import { Button } from "@adaptavant/core";

const AnnotateButton = ({ editMode, setEditMode, hasEditAccess }) => {
  return (hasEditAccess && !editMode) ? (
    <li>
      <Button
        variant="accentSecondary"
        size="large"
        onClick={() => setEditMode(true)}
        className="border border-solid"
      >
        Annotate
      </Button>
    </li>
  ) : null;
};

export default AnnotateButton;
