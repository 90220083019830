import React, { useState, useEffect } from 'react';
import Header from './Header';
import Main from './Main';
import { useParams } from 'react-router-dom';
import { getImageInfo } from '../util/get-image-info';
import { checkIsMobile } from '../util/check-is-mobile';

const App = ({ isDev }) => {
  const [fileName, setFileName] = useState(null);
  const [editor, setEditor] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [savingImage, setSavingImage] = useState(false);
  const [objects, setObjects] = useState([]);
  const [isRedoing, setIsRedoing] = useState(false);
  const [userSession, setUserSession] = useState({});
  const [isMobile] = useState(checkIsMobile());
  const { fullStorageKey } = useParams();
  useEffect(() => {
    if (isDev) {
      setFileName('AnySnap default');
      setUserSession({
        accountId: "testaccId",
        contactId: "testContactId",
        fullStorageKey: "default",
        hasAccess: "owner",
        photoId: 'S',
        storageURL: "https://storage.anywhereworks.com/files/fjlRTaPiqmcv/screenshot-2020-06-25-at-3-36-26-pm.png",
        fileName: "AnySnap default"
      });
    } else {
      getImageInfo(fullStorageKey).then((info) => {
        if (info.fileName) {
          setFileName(info.fileName);
        }
        setUserSession(info);
      }).catch((err) => {
        window.document.write(err.message);
      });
    }
  }, [fullStorageKey, isDev]);

  return (
    <div className="App">
      {
        (userSession.storageURL && userSession.contactId && userSession.accountId) ? (
          <>
            <Header
              fileName={fileName}
              setFileName={setFileName}
              contactId={userSession.contactId}
              accountId={userSession.accountId}
              fullStorageKey={fullStorageKey}
              editor={editor}
              editMode={editMode}
              setEditMode={setEditMode}
              setSavingImage={setSavingImage}
              savingImage={savingImage}
              setObjects={setObjects}
              objects={objects}
              setIsRedoing={setIsRedoing}
              isRedoing={isRedoing}
              userSession={userSession}
              isMobile={isMobile}
          />
            <Main
              imageURL={userSession.storageURL}
              editor={editor}
              setEditor={setEditor}
              editMode={editMode}
              savingImage={savingImage}
              isMobile={isMobile}
              fileName={fileName}
            />
          </>
        ) : null /* TODO: Get the fallback 404 not found page */
      }
    </div>
  );
}

export default App;
