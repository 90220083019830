import React, { useEffect } from "react";

const ArrowShape = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'arrow') {
      editor.off('mouse:down');
      editor.off('mouse:move');
      editor.off('mouse:up');

      const LineWithArrow = global.fabric.util.createClass(global.fabric.Line, {
        type: 'line_with_arrow',

        initialize(element, options) {
          if(!options) options = {};
          this.callSuper('initialize', element, options);

          // Set default options
          this.set({
            selectable: true,
            eventable: true,
            hasControls: true,
            hasBorders: false,
            shadow: new global.fabric.Shadow({
              blur: 2,
              offsetX: 0,
              offsetY: 0,
              affectStroke: true,
              color: 'rgba(0,0,0,0.8)',
            }),
            cornerColor: 'blue',
            cornerSize: 10,
            padding: -3,
            cornerStrokeColor: 'white',
            transparentCorners: false,
            cornerStyle: 'circle',
            hasRotatingPoint: false,
            borderScaleFactor: 2,
            objectCaching: false,
            strokeUniform: true
          });
        },

        _render(ctx) {
          this.callSuper('_render', ctx);
          if (this.width === 0 || this.height === 0 || !this.visible || !this.selectable) return;
          ctx.save();
          const xDiff = this.x2 - this.x1;
          const yDiff = this.y2 - this.y1;
          const angle = Math.atan2(yDiff, xDiff);
          console.log('diff :', xDiff, yDiff, angle);
          ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
          ctx.rotate(angle);
          ctx.beginPath();
          // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
          ctx.moveTo(10, 0);
          ctx.lineTo(-10, 10);
          ctx.lineTo(-10, -10);
          ctx.closePath();
          ctx.fillStyle = this.stroke;
          ctx.fill();
          ctx.restore();
        },
      });

      const drawLineWithArrow = (points) => (
         new LineWithArrow(points, {
           strokeWidth: 6,
           stroke: color
         })
       )

      let line;
      let isDown;

      editor.on('mouse:down', (options) => {
        options.e.stopPropagation();
        if (editor.findTarget(options.e)) return;
        isDown = true;
        const pointer = editor.getPointer(options.e);
        const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        line = drawLineWithArrow(points);
        line.setControlsVisibility({ml: false, mt: false, mb: false, mr: false});
        editor
        .add(line)
        .renderAll();
      });

      editor.on('mouse:move', (options) => {
        options.e.stopPropagation();
        if (!isDown) return;
        const pointer = editor.getPointer(options.e);
        line.set({ x2: pointer.x, y2: pointer.y });
        line.setCoords();
        // editor.setActiveObject(line)
        editor.renderAll();
      });

      editor.on('mouse:up', (options) => {
        options.e.stopPropagation();
        isDown = false;
      });
    }
  }, [editor, color, activeTool])

  return (
    <li className={`arrow ${activeTool === 'arrow' ? 'active' : ''}`} data-tooltip="Arrow">
      <i>
        <svg width="14" height="14" viewBox="0 0 14 14">
          <path fill="#FFF" d="M12.538 13.762c-.189.188-.494.188-.682 0l-9.7-9.7v8.814c0 .266-.216.482-.483.482H.548c-.266 0-.482-.216-.482-.482V.977c0-.266.216-.482.482-.482h11.9c.266 0 .482.216.482.482v1.125c0 .266-.216.482-.483.482H3.633l9.7 9.7c.188.189.188.494 0 .682l-.795.796z" />
        </svg>
      </i>
    </li>
  )
}

export default ArrowShape;
