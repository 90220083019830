import React from 'react';

const SavingOverlay = () => {
  return (
    <div className="image-saver">
      <span>
        Saving...
      </span>
    </div>
  );
};

export default SavingOverlay;
