import React from 'react';
import { NavLink } from 'react-router-dom';
import EditorOptions from './EditorOptions';
import FileName from './FileName';
import AnnotateButton from './AnnotateButton';
import CopyLinkButton from './CopyLinkButton';
import UserProfile from './UserProfile';

const Header = (props) => {
  return (
    <header>
      <NavLink to="#" className="logo">
        <img src={process.env.PUBLIC_URL + '/imgs/anysnap-logo.svg'} alt="site logo" />
      </NavLink>
      <FileName hasEditAccess={props.userSession.hasAccess === 'owner'} fileName={props.fileName} setFileName={props.setFileName} fullStorageKey={props.fullStorageKey} />
      <ul className="right-header">
        <EditorOptions {...props} />
        <AnnotateButton
          hasEditAccess={props.userSession.hasAccess === 'owner' && !props.isMobile}
          editMode={props.editMode}
          setEditMode={props.setEditMode}
        />
        <CopyLinkButton />
        <UserProfile
          photoId={props.userSession.photoId}
          fullStorageKey={props.fullStorageKey}
          isMobile={props.isMobile}
        />
      </ul>
    </header>
  );
}

export default Header;
