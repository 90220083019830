export const toolsList = [
  'text',
  'rectangle',
  'arrow',
  'pencil',
  'circle',
  'line',
  'eraser',
  'pixelate'
];
