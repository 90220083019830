export const uploadImage = async (imageData, contactId, accountId, fullStorageKey, fileName) => {
  const getUploadURLResult = await fetch(`${global.location.origin}/update-image?contactId=${contactId}&accountId=${accountId}&storageKey=${fullStorageKey}&fileName=${fileName}`);
  const { uploadURL } = await getUploadURLResult.json();
  const typedArray = convertDataURIToBinary(imageData);
  const blob = new Blob([typedArray], { type: 'image/png' });
  const form = new FormData();
  form.append('file', blob, fileName);
  const uploadResult = await fetch(uploadURL, {
    method: 'POST',
    body: form
  });
  if (uploadResult.ok) {
    return fullStorageKey;
  }
  throw new Error('failed to upload');
};

function convertDataURIToBinary(dataURI) {
  const BASE64_MARKER = ';base64,';
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(rawLength);

  for(let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
