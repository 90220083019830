import React, { useEffect } from "react";

const RectangleShape = ({ editor, color, activeTool }) => {
  useEffect(() => {
    if (editor && color && activeTool === 'rectangle') {
      editor.off('mouse:down');
      editor.off('mouse:move');
      editor.off('mouse:up');
      let rect, isDown;
      let x, y = 0;
      editor.on('mouse:down', function (o) {
        o.e.stopPropagation();
        if (editor.findTarget(o.e)) return;
        isDown = true;
        let mouse = editor.getPointer(o.e);
        x = mouse.x;
        y = mouse.y;

        rect = new global.fabric.Rect({
            width: 0,
            height: 0,
            stroke: color,
            strokeWidth: 6,
            top: y,
            left: x,
            fill: '#ffffff00',
            selectable: true,
            eventable: true,
            hasControls: true,
            shadow: new global.fabric.Shadow({
              blur: 2,
              offsetX: 0,
              offsetY: 0,
              affectStroke: true,
              color: 'rgba(0,0,0,0.8)',
            }),
            hasBorders: false,
            cornerColor: 'blue',
            cornerSize: 10,
            padding: -3,
            cornerStrokeColor: 'white',
            transparentCorners: false,
            cornerStyle: 'circle',
            hasRotatingPoint: false,
            borderScaleFactor: 2,
            objectCaching: false,
            strokeUniform: true
        });

        rect.setControlsVisibility({ml: false, mt: false, mb: false, mr: false});
        editor.add(rect);
        editor.renderAll();
      });
      editor.on('mouse:move', function(o) {
        o.e.stopPropagation();
        if (!isDown) return false;
        let mouse = editor.getPointer(o.e);
        let w = Math.abs(mouse.x - x),
          h = Math.abs(mouse.y - y);

        if (!w || !h) {
          return false;
        }

        rect.set('width', w).set('height', h);
        if (x > mouse.x) {
          rect.set('originX', 'right');
        } else {
          rect.set('originX', 'left');
        }

        if (y > mouse.y) {
          rect.set('originY', 'bottom');
        } else {
          rect.set('originY', 'top');
        }
        // rect.set('strokeWidth', 4);
        rect.setCoords();
        // editor.setActiveObject(rect);
        editor.renderAll();
      });

      editor.on('mouse:up', function (o) {
          o.e.stopPropagation();
          if(isDown) {
            isDown = false;
          }
      });

          editor.isDrawingMode = false;
     }
    }, [editor, color, activeTool]);
  return (
    <li className={`rectangle ${activeTool === 'rectangle' ? 'active' : ''}`} data-tooltip="Rectangle">
      <i>
        <svg width="22" height="16" viewBox="0 0 22 16">
          <path
            fill="#FFF"
            d="M19.277 15.714c1.064 0 1.928-.864 1.928-1.928V2.214c0-1.064-.864-1.928-1.928-1.928H2.643C1.578.286.714 1.15.714 2.214v11.572c0 1.064.864 1.928 1.929 1.928h16.634zm-.241-1.928H2.884c-.133 0-.241-.109-.241-.241V2.455c0-.132.108-.24.24-.24h16.153c.132 0 .24.108.24.24v11.09c0 .132-.108.24-.24.24z"
          />
        </svg>
      </i>
    </li>
  )
}

export default RectangleShape;
