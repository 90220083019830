export function handleDeleteObject(editor, event) {
  if (event.isComposing || event.keyCode === 229) {
    return;
  }
  if(event.keyCode === 46 || event.keyCode === 8) {
    const activeEl = document.activeElement;
    const matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
    };
    if (matches(activeEl, 'input,textarea,text,password,file,email,search,tel,date')) {
      return;
    }
    const activeObjects = editor.getActiveObjects();
    editor.discardActiveObject() ;
    if (activeObjects.length) {
      editor.remove(...activeObjects);
    }
    editor.renderAll()
    event.preventDefault();
  }
}
