import { handleDeleteObject } from './handle-delete-object';
import { handleGlobalClick } from './handle-global-click';

export const attachEventListeners = (editor) => {
  // Attach listener and function alone. don't define the function here.
  document.addEventListener("keyup", handleDeleteObject.bind(null, editor));
  document.addEventListener("click", handleGlobalClick);
};

export const removeEventListeners = (editor) => {
  document.removeEventListener("keyup", handleDeleteObject.bind(null, editor));
  document.removeEventListener("click", handleGlobalClick);
};
