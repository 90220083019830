import React from "react";
import { Avatar, AvatarImage } from "@adaptavant/core";

const UserProfile = ({ photoId, isMobile, fullStorageKey }) => {
  const getLoginPage = async (event) => {
    event.stopPropagation();
    let result = await fetch(
      `${global.location.origin}/login?fullStorageKey=${fullStorageKey}`
    );
    let { data } = await result.json();
    if (data) {
      window.location.assign(data);
    }
  };
  return !isMobile ? (
    photoId ? (
      <li className="profile-pic">
        {photoId.length > 1 ? (
          <Avatar size="40" className="ml-4">
            <AvatarImage src={photoId} />
          </Avatar>
        ) : (
          <Avatar name={photoId} size="40" className="ml-4"/>
        )}
      </li>
    ) : (
      <li>
        <button className="button" onClick={getLoginPage}>
          Login
        </button>
      </li>
    )
  ) : null;
};

export default UserProfile;
