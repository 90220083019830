import React, { useEffect } from 'react';
import { loadImageFromURL } from '../util/load-image-from-url';
import { initializeFabricCanvas } from '../util/initialize-fabric-canvas';
import { attachEventListeners, removeEventListeners } from '../util/attach-event-listeners';
import { MenuProvider } from 'react-contexify';
import EditorContextMenu from './EditorContextMenu';

const Editor = ({imageURL, setEditor, setShowImageLoader, editor, fileName}) => {
  useEffect(() => {
    const canvas = global.editor = initializeFabricCanvas();
    attachEventListeners(canvas);
    setEditor(canvas);
    loadImageFromURL(imageURL).then((image) => {
      canvas.setBackgroundImage(image, () => {
        canvas.requestRenderAll();
        canvas.setWidth(image.getScaledWidth());
        canvas.setHeight(image.getScaledHeight());
        setShowImageLoader(false);
      }, {
        top: 0,
        left: 0,
        crossOrigin: 'anonymous'
      });
    }).catch(console.error); // TODO: Add Image Error Handler Design in Catch Block.
    return () => {
      removeEventListeners(canvas);
    }
  }, [imageURL, setEditor, setShowImageLoader]);

  return (
    <>
      <MenuProvider id="menu_id">
      <div className="editing-img">
        <canvas id="main"></canvas>
      </div>
      </MenuProvider>
      <EditorContextMenu editor={editor} fileName={fileName} />
    </>
  );
};

export default Editor;
