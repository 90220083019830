import React, { useState, useEffect } from "react";
import CustomPicker from "./custom-picker";

const ColorPickerOption = ({ color, setColor }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const handleColorChange = ({ hex }) => setColor(hex);
  const toggleColorPicker = (event) => {
    if (event && !event.target.closest('div.color-picker-wrp')) {
      event.stopPropagation();
      setShowPicker(!showPicker);
      if(!showPicker) {
        setShowCustomPicker(false);
      }
    }
  };
  const toggleCustomColorPicker = (event) => {
    event.stopPropagation();
    setShowCustomPicker(!showCustomPicker);
  }
  const handleColorListClick = (event) => {
    event.stopPropagation();
    if (event.target
      && event.target.tagName === 'SPAN'
      && !event.target.closest('span.custom-colors')
      && event.target.style
      && event.target.style.backgroundColor
    ) {
      setColor(event.target.style.backgroundColor);
    }
  }

  useEffect(() => {
    const hideColorPicker = () => setShowPicker(false);
    document.addEventListener('hide-color-picker', hideColorPicker);
    return () => {
      document.removeEventListener('hide-color-picker', hideColorPicker);
    }
  }, [setShowPicker]);

  return (
    <li className="color-picker" onClick={toggleColorPicker} data-tooltip="Stroke Color">
      <span style={{ background: color }}></span>
      {showPicker ? (
        <div className="custom-color-picker">
          <div className="colors-list" onClick={handleColorListClick} >
            <span style={{ 'backgroundColor': "#000000" }}></span>
            <span style={{ 'backgroundColor': "#ffffff" }}></span>
            <span style={{ 'backgroundColor': "#d0021b" }}></span>
            <span style={{ 'backgroundColor': "#6285ff" }}></span>
            <span style={{ 'backgroundColor': "#417505" }}></span>
            <span style={{ 'backgroundColor': "#f5a623" }}></span>
            <span style={{ 'backgroundColor': "#bd10e0" }}></span>
            <span className="custom-colors" onClick={toggleCustomColorPicker} ></span>
            {showCustomPicker ? (
              <CustomPicker
                color={color}
                onChangeComplete={handleColorChange}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </li>
  );
}
export default ColorPickerOption;
