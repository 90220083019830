import React, { useEffect } from 'react';
import { ReplyIcon,IconButton } from '@adaptavant/core';

function undoOption(props) {
  let objects = props.objects;
  if (props.editor._objects.length > 0) {
    console.log(props.editor._objects);
    let editObject = props.editor._objects;
    if (editObject[editObject.length - 1].width <= 0 && editObject[editObject.length - 1].height <= 0) {
      props.editor._objects.pop();
    }
    objects.push(props.editor._objects.pop());
    props.setObjects(objects);
    props.editor.renderAll();
  }
}

export default function UndoIcon(props) {

  useEffect(() => {
    const undo = (e) => {
      if ((e.keyCode === 90 && e.metaKey && !e.shiftKey) || (e.keyCode === 90 && e.ctrlKey)) {
        undoOption(props);
      }
    };
    document.addEventListener('keydown', undo);
    return () => document.removeEventListener('keydown', undo);
  }, [props]);

  return (
    <li>
      <IconButton aria-label="Click me!"
          className="ml-2"
          size="standard"
          onClick={() => { undoOption(props) }}
          icon={ReplyIcon}
          variant= "accentPrimary"/>
    </li>
  );
}
